
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import { CONDITIONS_CATEGORY } from "../../../constants/constants";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";
@Component
export default class EditCondition extends Vue {
  /* eslint-disable */
  public conditionsList = [];
  public conditionsData: any = {};
  public conditionsCategory = CONDITIONS_CATEGORY;
  public isEdit = false;
  public isHide = true;
  $modal: any;
  public editor: any = null;
  public isError = false;
  public inputId = 0; 
  public errorName = "";
  public errorCategory = "";
  public search = "";
  public jsonData: any = [];
  public nameError = false

  /* eslint-disable */

  public addCondition() {
    this.$router.push({ path: "/manage-conditions"});
  }

    public async saveConditions() {
      this.callLoader(true, null); 
    try {
      if(!this.conditionsData.category){
        this.isError = true;
        this.errorCategory = "Please add a Category";
      }
      this.conditionsData.name = this.editor.getContents();
      if (
        this.conditionsData.name
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != ""
      ) {
        this.errorName = "";
        this.nameError = false;
      }else {
        this.nameError = true
        // this.isError = true;
        this.errorName = "Please add Description";
        // this.errorCategory = "Please add a Category";
      }
      if (
        this.conditionsData.name
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != "" &&
        this.conditionsData.category
      ) {
        const response = await Axios.post(
          BASE_API_URL + "super-admin/conditions/saveConditions",
          this.conditionsData,
          {
            headers: authHeader(),
          }
        );
        if (response.data.msg === "success") {
          this.$snotify.success(
            this.isEdit ? "Updated Sucessfully" : "Saved Successfully"
          );
          this.isError = false;
          this.errorCategory = "";
          if(!this.isEdit){
          this.conditionsData = {};
          this.$router.push({ path: "/manage-conditions"});
          }
          this.inputId = 0;
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }
   public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

   public async getSelectedCondition() {
     this.callLoader(true, null);
    try {
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/conditions/getSelectedCondition/${this.$route.query._id}`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.conditionsData = response.data;

      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }


  public insertInput() {
    this.inputId += 1;
    this.editor.insertHTML(
      `<input id='input-${this.inputId}' name='value' type='text' disabled />`,
      true,
      true
    );
  }

  async mounted() {
    // await this.getSelectedCondition()
    if (this.$route.query._id) {
      await this.getSelectedCondition()
      this.isEdit = !this.isEdit;
    }
    setTimeout(async () => {  
      this.editor = await suneditor.create("pageEditor", {
        plugins: {
          ...plugins,
        },
        width: "auto",
        height: "100px",
        buttonList: [["bold"], ["list"]],
        
      });
      
    this.editor.insertHTML(this.conditionsData.name,true,true);
    }, 1,true);
    }
  }
